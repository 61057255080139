import {
    Route,
    Redirect,
    Switch,
} from "react-router-dom";
import InitiateOauthSignIn from "../initiateOauthSignIn/initiateOauthSignIn";
import SignInPage from "../signInPage/signInPage";
import LoginSuccessPage from "../loginSuccessPage/loginSuccessPage";
import InitiateEmailLinkSignIn from "../initiateEmailLinkSignIn/initiateEmailLinkSignIn";
import CompleteEmailLinkSignIn from "../completeEmailLinkSignIn/completeEmailLinkSignIn";

const SignInRouter = ({ url }) => {
    return ( 
        <div>
            <Switch>
                <Route exact path={url}>
                    <SignInPage />
                </Route>
                <Route exact path={`${url}/initiate/oauth`}>
                    <InitiateOauthSignIn />
                </Route>
                <Route exact path={`${url}/initiate/emaillink`}>
                    <InitiateEmailLinkSignIn />
                </Route>
                <Route exact path={`${url}/complete/emaillink`}>
                    <CompleteEmailLinkSignIn />
                </Route>
                <Route exact path={`${url}/success`}>
                    <LoginSuccessPage />
                </Route>
                <Redirect from={`${url}*`} to={url} />
            </Switch>
        </div>
    );
}
 
export default SignInRouter;