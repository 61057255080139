import "./signInPage.css";
import logo from '../../assets/images/selectext-logo-filled.svg'
import SignInButton from "../../components/signInButton/signInButton";
import microsoftLogo from "../../assets/images/microsoft-logo.svg";
import googleLogo from "../../assets/images/google-logo.svg";
import facebookLogo from "../../assets/images/facebook-logo.png";
import emailIcon from "../../assets/images/email-icon.png";
import { useLocation } from "react-router-dom";
import * as qs from 'qs';

const SignInPage = () => {
  const location = useLocation();
  const redirect = qs.parse(location.search, { ignoreQueryPrefix: true }).redirect || "extension";
  
  const getLinkAddressForOauthSignInMethod = (method) => `/signin/initiate/oauth?method=${method}&redirect=${redirect}`;

  return ( 
  <div className="signInPageWrapper">
    <div className="signInContentWrapper">
      <img src={logo} alt="Selectext Logo" className="selectextLogoSignin"></ img>
      <div className="signinMethodsWrapper">
        <SignInButton logo={googleLogo} linkAddress={getLinkAddressForOauthSignInMethod("google")}>Sign in with Google</SignInButton>
        <SignInButton logo={facebookLogo} linkAddress={getLinkAddressForOauthSignInMethod("facebook")}>Sign in with Facebook</SignInButton>
        <SignInButton logo={microsoftLogo} linkAddress={getLinkAddressForOauthSignInMethod("microsoft")}>Sign in with Microsoft</SignInButton>
        <SignInButton logo={emailIcon} linkAddress={`/signin/initiate/emaillink?redirect=${redirect}`}>Sign in with email link</SignInButton>
      </div>
    </div>
  </div>
  );
}
 
export default SignInPage;