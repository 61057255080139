import "./signInButton.css";
import { Link } from "react-router-dom";


const SignInButton = ({ children, logo, linkAddress }) => {
    return ( 
        <Link to={linkAddress} class="gsi-material-button selectextLoginWithGoogleButton">
            <div class="gsi-material-button-state"></div>
            <div class="gsi-material-button-content-wrapper">
                <div class="gsi-material-button-icon">
                <img className="signinIcon" src={logo} alt="Sign In Logo"></img>
                </div>
                <span class="gsi-material-button-contents selectextSignInWithGoogleText">{children}</span>
                <span style={{display: "none"}}>{children}</span>
            </div>
        </Link>
    );
}
 
export default SignInButton;