import './loginSuccessPage.css';  
import logo from '../../assets/images/selectext-logo-filled.svg'
import { useEffect } from 'react';


const LoginSuccessPage = () => {
  useEffect(() => {
    window.postMessage({ type: "SELECTEXT_SIGN_IN_SUCCESS" }, "*");
  }, []); // Empty dependency array ensures this runs only once on render

  return ( 
    <div className={"loginSuccessPageWrapper"}>
      <img src={logo} className={"loginSuccessLogo"} alt={"Selectext logo"}></img>
      <h1>You have successfully signed in to Selectext.</h1>
      <h3>You can now close this tab and return to the video.</h3>
    </div>
  );
}
 
export default LoginSuccessPage;