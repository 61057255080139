import firebase from 'firebase/compat/app';
import "firebase/compat/auth";


// Firebase Config
const config = {
  apiKey: "AIzaSyDILe00dGfUEX1yq1jq-b75dkiIv_lV6Jg",
  authDomain: "login.selectext.app",
  projectId: "tough-access-318906",
  storageBucket: "tough-access-318906.appspot.com",
  messagingSenderId: "354296970601",
  appId: "1:354296970601:web:cf43f36bab77c6c68cd1f8",
  measurementId: "G-3NKQNRB0F8"
};

firebase.initializeApp(config);

export default firebase;