import * as React from "react";
import { requestApi } from "../../utils/api";
import { useEffect } from "react";
import Loading from "../loading/loading"
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";

const FirebaseLogout = () => {
    const history = useHistory()

    useEffect(
      () => {
        firebase.auth().signOut().then(
          (firebaseRes) => {
            requestApi("logout", 'GET', undefined, undefined, true).catch(
              console.error
            ).then(
              (res) => {
                history.push("/");
              }
            )
          },
          (firebaseErr) => {
            console.log(firebaseErr)
          }
        ) 
      }, [history]
    )

    return ( 
        <div className={"fullscreenCenter"}>
            <div className={"loadingWrapper"}>
                <Loading />
            </div>
        </div>
    );
}
 
export default FirebaseLogout;