import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import * as qs from 'qs';
import { requestApi } from "../../utils/api";
import Loading from "../../components/loading/loading";
import { useCallback } from "react";
import { OAuthProvider } from "firebase/auth";
import firebase from "../../firebase";
import "./initiateOauthSignIn.css";


const InitiateOauthSignIn = () => {
  const location = useLocation();
  const [authStatus, setAuthStatus] = useState("loading");
  const [authFailedMessage, setAuthFailedMessage] = useState("");
  const parsedQs = qs.parse(location.search, { ignoreQueryPrefix: true });
  const method = parsedQs.method || "google"; // Default to Google
  const redirect = parsedQs.redirect || "extension"; // Default redirect behavior
  const history = useHistory();
  const getProvider = useCallback((providerId) => {
    switch (providerId) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        googleProvider.setCustomParameters({
          prompt: 'select_account'
        })
        return googleProvider;
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
        return new firebase.auth.FacebookAuthProvider();
      case "microsoft.com":
        const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com'); 
        microsoftProvider.setCustomParameters({
          prompt: "consent",
        })
        return microsoftProvider
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }, [])
  const signInWithProvider = useCallback(async (provider) => {
    try {
      // const providerInstance = new firebase.auth[`${provider}AuthProvider`]();
      const providerInstance = getProvider(provider)
      
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await firebase.auth().signInWithRedirect(providerInstance);
    } catch (error) {
      console.error(error);
    }
  }, [getProvider])
  const getCurrentUser = useCallback(async () => {
    return await new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(
        (user) => {
          unsubscribe()
          resolve(user)
        },
        reject
      )
    })
  }, [])
  const signInWithGoogle = useCallback(() => signInWithProvider(firebase.auth.GoogleAuthProvider.PROVIDER_ID), [signInWithProvider])
  const signInWithFacebook = useCallback(() => signInWithProvider(firebase.auth.FacebookAuthProvider.PROVIDER_ID), [signInWithProvider]);
  const signInWithMicrosoft = useCallback(() => signInWithProvider("microsoft.com"), [signInWithProvider]);
  
  const setAuthFailed = useCallback((message, red) => {
    setAuthStatus("failed");
    setAuthFailedMessage(message);
    if (!red) {
      red = "/signin"
    }
    setTimeout(() => {
      history.push(red);
    }, 4000)
  }, [history])

  const getAndSendIDToken = useCallback(async (currentUser) => {
    try {
      const idToken = await currentUser.getIdToken(true);

      const response = await requestApi(
        "login",
        'POST',
        null,
        { "Authorization": `Bearer ${idToken}` },
        true
      );
      if (response) {
        setAuthStatus("success")  // Set authentication success state
      } else {
        setAuthFailed("There was an error logging in with Firebase");
      }
    } catch (error) {
      console.error(error);
    }
  }, [setAuthFailed])
  useEffect(() => {
    const checkRedirectResult = async () => {
      try {
        const result = await firebase.auth().getRedirectResult();
        if (result.credential) {
          await getAndSendIDToken(result.user);
        } else {
          switch (method.toLowerCase()) {
            case "google":
              signInWithGoogle();
              break;
            case "facebook":
              signInWithFacebook();
              break;
            case "microsoft":
              signInWithMicrosoft();
              break;
            default:
              console.error("Unknown sign-in method:", method);
              break;
          }
        }



      } catch (e) {
        console.log({ e })

        if (e.code === 'auth/account-exists-with-different-credential') {
          let currentUser = await getCurrentUser();
          if (currentUser === null) {
            const providers = e.customData._tokenResponse.verifiedProvider;
            if (!providers) {
              // Previous sign in method was email link
              // Show message to user to sign in with email link, then redirect to email link sign in page
              setAuthFailed("You previously signed in with an email link. Please sign in using email link to continue.", `/signin/initiate/emaillink?redirect=${redirect}`);
              return;
            } else {
              try {
                const previouslySignedInProvider = getProvider(providers[0]);
                previouslySignedInProvider.setCustomParameters({ login_hint: e.customData.email });
                await firebase.auth().signInWithPopup(previouslySignedInProvider);
                
              } catch (e) {
                setAuthFailed(`Failed to sign in with previous provider: ${providers}. Please try signing in from the beginning with that provider.`);
                return
              }
            }
            currentUser = await getCurrentUser();
          }
          const credential = OAuthProvider.credentialFromError(e);
          await currentUser.linkWithCredential(credential);
          await getAndSendIDToken(currentUser);
        }
        else {
          setAuthFailed("Sign in failed for an unknown reason. Please try again.");
        }

      } 
    };
    checkRedirectResult();
  }, [getAndSendIDToken, method, signInWithFacebook, signInWithGoogle, signInWithMicrosoft, getCurrentUser, getProvider, setAuthFailed, redirect])
   
switch (authStatus) {
  case "loading":
    return (
      <div className={"fullscreenCenter"}>
        <div className={"loadingWrapper"}>
          <Loading />
        </div>
      </div>
    );
  case "success":
    if (redirect === "portal") {
      return <Redirect to="/portal" />;
    } else {
      return <Redirect to="/signin/success" />;
    }
  case "failed":
    return <div className='initiateEmailLinkSignInWrapper'>
      <h2>Sign in failed</h2>
      <p>{authFailedMessage}</p>
      <div className="ellipsis">
        <span>.</span><span>.</span><span>.</span>
      </div>
    </div>
  default:
    return <div>Invalid status</div>
}
};

export default InitiateOauthSignIn;
