import './topNavBar.css';
import smallIcon from '../../assets/images/selectext-small-logo-blue.png'
import { Button } from '@material-ui/core';
import TopNavBarOption from '../topNavBarOption/topNavBarOption';
import { Link } from "react-router-dom";

const TopNavBar = () => {
    return ( <div className={"topnavbarwrapper"}>
        <Link to="/"><img alt="selectextLogo" className={"smallIcon"} src={smallIcon} /></Link>
        <div className="topNavBarOptionsWrapper">
            <TopNavBarOption text={"Tutorial"} to={"/tutorial?n=1"}/>
        </div>
        <Link to="/portal/account" className="gotoportalbutton"><Button className={"donateButton logInButton"}>My Account</Button></Link>
    </div> );
}
 
export default TopNavBar;