import './privacyPolicyPage.css';

const PrivacyPolicyPage = () => {
    return (
        <div className="privacyPolicyWrapper">
            <h1>Selectext Privacy Policy</h1>
            <p>We do not sell your information. We don't help companies advertise their products to you. All information collected is stored securely and used internally only.</p>
            <br />
            <p>We offer a free service and hope you will upgrade to our paid versions. That's the only way we make money.</p>
            <br />
            <h2>Data Collected by the Selectext Extension and Website</h2>
            <p>We need you to sign so we can limit usage of the extension per user to keep our server costs under control. When you sign in , your email address, full name, and picture are automatically collected and stored securely by the Firebase Authentication service provided by Google. Please refer to the Firebase privacy policy for more information <a href="https://firebase.google.com/support/privacy" rel="noreferrer" target="_blank">https://firebase.google.com/support/privacy</a>.</p>
            <br />
            <p>Basic usage information is collected when you use the extension in order to limit usage per user, to understand when the extension is not working, and for high level analytics internally to understand how to prioritise improvements to the extension. Data is only collected when you interact with the extension. This includes the website URL where the extension was used, and information about the text detected in the video such as the language. No data unrelated to the extension is collected. This data is stored securely in our database.</p>
            <br />
            <p>Stripe is used to securely store your payment information and to process payments when you subscribe to a paid Selectext plan via the selectext.app website. Your card information goes directly to Stripe, and is not stored in Selectext's database. Please refer to the Stripe privacy policy for more information <a href="https://stripe.com/privacy" rel="noreferrer" target="_blank">https://stripe.com/privacy</a>.</p>
            <br />
            <br />
            <p>If you have any questions or concerns about this policy, you can contact us via support@selectext.app.</p>
            <br />
            <br />
            <p>Last updated: 22 November 2022</p>
        </div>
    );
}

export default PrivacyPolicyPage