/**
 * Global Config
 */

const config = {}

config.domains = {}

config.freePlanInfo = {
  credits: 20,
  name: "Free",
  cost: 0
};



if (process.env.REACT_APP_STAGE === "prod") {
  config.domains.api = "https://api.selectext.app";
  config.stripeKey = "pk_live_51IdAMTB778qqid76mIf1CLGoFwwevNCDpK2oBebXLQi4b5pD9p895I3Yq0AkFzMzR0PErlNDV9b5pbLiQ4fX2kEm00mUOxf2bE"
  config.domains.siteUrl = "https://selectext.app";
} else {
  config.domains.api = "https://devapi.selectext.app";
  config.stripeKey = "pk_test_51IdAMTB778qqid76johow7VqjKWdmihVCCylB8Mmp6NLtL7eznYw5jRmu3Aw5i0wHvvt4qc2XpQkWAAYZoAnTZFj00Z4H7yPj2"
  config.domains.siteUrl = "https://dev.selectext.app";
  if (process.env.REACT_APP_STAGE === "local") {
    config.domains.siteUrl = "http://localhost:3000";
  }
}


config.webStoreUrls = {
  chrome: "https://chromewebstore.google.com/detail/selectext-copy-text-from/gkkdmjjodidppndkbkhhknakbeflbomf",
  firefox: "https://addons.mozilla.org/en-CA/firefox/addon/selectext/"
}

config.promoVideoUrl = "https://www.youtube.com/watch?v=9AbDNKli6m8";

export default config