import { useEffect, useCallback, useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import firebase from '../../firebase'; // Make sure to import your firebase configuration
import { requestApi } from '../../utils/api';
import Loading from '../../components/loading/loading';
import { Redirect } from 'react-router-dom';
import qs from 'qs';

const CompleteEmailLinkSignIn = () => {
    const location = useLocation();
    const history = useHistory();

    // use qs to get the redirect query parameter
    const parsedQs = qs.parse(location.search, { ignoreQueryPrefix: true });
    const redirect = parsedQs.redirect || "portal"; // Default redirect behavior


    // auth status state
    const [authStatus, setAuthStatus] = useState("loading");


    const getAndSendIDToken = useCallback(async (currentUser) => {
        try {
          const idToken = await currentUser.getIdToken(true);
    
          const response = await requestApi(
            "login",
            'POST',
            null,
            { "Authorization": `Bearer ${idToken}` },
            true
          );
          if (response) {
            setAuthStatus("success")  // Set authentication success state
          } else {
            console.log("There was an error logging in with Firebase");
            setAuthStatus("failed");
          }
        } catch (error) {
          console.error(error);
        }
        
      }, [])
    useEffect(() => {
        const handleSignIn = async () => {
            try {
                if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
                    let email = window.localStorage.getItem('emailForSignIn');
                    if (!email) {
                        email = window.prompt('Please provide your email for confirmation');
                    }
                    const result = await firebase.auth().signInWithEmailLink(email, window.location.href);
                    // get and send id token with current user
                    getAndSendIDToken(result.user)


                    window.localStorage.removeItem('emailForSignIn');
                    console.log('Successfully signed in', result);
                    
                }
            } catch (error) {
                console.error('Error signing in with email link', error);
            }
        };

        handleSignIn();
    }, [location, history, getAndSendIDToken]);

    switch (authStatus) {
      case "loading":
        return (
          <div className={"fullscreenCenter"}>
            <div className={"loadingWrapper"}>
              <Loading />
            </div>
          </div>
        );
      case "success":
        if (redirect === "portal") {
          return <Redirect to="/portal" />;
        } else {
          return <Redirect to="/signin/success" />;
        }
      case "failed":
        return <div>Login failed</div>
      default:
        return <div>Invalid status</div>
    }
};

export default CompleteEmailLinkSignIn;